function Donate() {
    return (
        <>
            <ul>
                <li><a href='https://ko-fi.com/O4O6BD3G9' target='_blank' rel="noreferrer">Ko-Fi</a></li>
                <li><a href="https://www.paypal.com/donate/?hosted_button_id=DWRW35XL8AYXL" target="_blank" rel="noreferrer">Paypal</a></li>
            </ul>


        </>
    )
};

export default Donate;